import { React } from 'react';
import '../App.css';

import CustomLink from './CustomLink'; // Adjust the import path as necessary

import DiscordButton from './DiscordButton';
import PatreonButton from './PatreonButton';
import KofiButton from './KofiButton';


const Footer = (thereAreVideos, setThereAreVideos) => {




    return (
        <div className="footer">
            <span className="footerleft">

                <ul className="list">


                    <li className="listItem">
                        <CustomLink className="link" to="/TheProject"
                            thereAreVideos={thereAreVideos}
                            setThereAreVideos={setThereAreVideos}
                        >
                            The Project
                        </CustomLink>
                    </li>

                    <li className="listItem"> 
                <CustomLink className="link" to="/Privacy"
                    thereAreVideos={thereAreVideos}
                    setThereAreVideos={setThereAreVideos}
                >
                    Privacy Policy
                </CustomLink>
                    </li>                                    

                    <li className="listItem">
                        <CustomLink className="link" to="/Contact"
                            thereAreVideos={thereAreVideos}
                            setThereAreVideos={setThereAreVideos}
                        >
                            Contact Me
                        </CustomLink>
                    </li>


                </ul>
            </span>


            <span className="footerleft">
                <ul className="list">
                    <li className="listItem">
                        <DiscordButton />
                    </li>
                    <li className="listItem">
                        <PatreonButton />
                    </li>

                    <li className="listItem">
                        <KofiButton />
                    </li>
                </ul>
            </span>



            <ul className="list">
                <li className="listItem"> <p>v1.0</p></li>
                <li className="listItem">
                {/*    <p>All Rights Reserved</p>*/}
                </li>
                <li className="listItem"> <p>Created 2024</p></li>
                <li className="listItem">Jyro</li>
            </ul>
        </div>
    );
};

export default Footer;


