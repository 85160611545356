import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRendering } from './RenderingContext'; // Adjust the import path as necessary

const CustomLink = ({ to, children, thereAreVideos, setThereAreVideos, ...props }) => {
    const { isRendering } = useRendering();
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = (e) => {
        e.preventDefault();

        if (isRendering) {
            alert('Rendering is in progress. Please wait until it is complete.');
            return; // Ensure no further action is taken
        }

        const pathsToSkipAlert = ['/', '/login', '/Home', '/page', '/Privacy', '/Contact', '/TheProject'];

        if (!pathsToSkipAlert.includes(location.pathname)) {
            const userConfirmed = window.confirm('Leave this page? Changes you made may not be saved.');
            if (!userConfirmed) {
                return; // Ensure no navigation if user cancels
            }
           // setThereAreVideos(false);
        }

        navigate(to);
    };

    return (
        <a href={to} onClick={handleClick} {...props}>
            {children}
        </a>
    );
};

export default CustomLink;
